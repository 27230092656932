import cn from "classnames";
import parse from "html-react-parser";
import { ReactElement } from "react";

import Details from "../../molecules/details/Details";
import SectionTitle from "../../molecules/section-title/SectionTitle";
import useVisibilityTracker from "../../utils/hooks/useVisibilityTracker";
import * as styles from "./Faqs.module.scss";

interface FaqItem {
  title?: string;
  text?: string;
}

interface FaqsProps {
  title: string;
  faqItems: FaqItem[];
  adoptionClassName?: string;
}

const PreSalesFaqs = ({ title, faqItems, adoptionClassName }: FaqsProps): ReactElement => {
  const { ref } = useVisibilityTracker("faqs", {
    amount: 0.5,
  });

  return (
    <section ref={ref} className={cn(styles.faqs, adoptionClassName, "or-faqs")}>
      <SectionTitle title={parse(title)} adoptionClassName={styles.sectionTitle} />
      {faqItems.map(({ title, text }, index) => {
        return (
          <Details key={index} title={title} trackingId={`faq-id-${index}`}>
            {text && parse(text)}
          </Details>
        );
      })}
    </section>
  );
};

export default PreSalesFaqs;
