import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";

import * as styles from "../../core/ui/organisms/footer/Footer.module.scss";
import logoSVG from "../../images/logo/barkibu.svg";
import useVisibilityTracker from "../../utils/hooks/useVisibilityTracker";

interface FooterProps {
  adoptionClassName?: string;
}

const PreSalesFooter = ({ adoptionClassName }: FooterProps): JSX.Element => {
  const { t } = useTranslation();
  const { ref } = useVisibilityTracker("footer", {
    amount: 0.5,
  });

  return (
    <footer ref={ref} className={cn("or-footer", styles.footer, adoptionClassName)}>
      <img
        src={logoSVG}
        alt={t("landing.footer.logo.alt")}
        width="111"
        height="24"
        loading={"lazy"}
      />
      <section className={styles.disclaimers}>
        <p>{parse(t("landing.footer.disclaimer.barkibu.text"))}</p>
        <p>{parse(t("landing.footer.disclaimer.google.text"))}</p>
        <p>{parse(t("landing.footer.disclaimer.copyright.text"))}</p>
      </section>
    </footer>
  );
};

export default PreSalesFooter;
