import cn from "classnames";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React, { ReactElement } from "react";

import BackgroundShape from "../../atoms/background-shape/BackgroundShape";
import Icons from "../../core/ui/images/icomoon/symbol-defs.svg";
import { useCountry } from "../../func-core/core/application/hooks/useCountry";
import DogAndCatSvg from "../../images/templates/pre-sales/dog-and-cat.svg";
import PhoneCallSvg from "../../images/templates/pre-sales/phone-call.svg";
import SyringeSvg from "../../images/templates/pre-sales/syringe.svg";
import WorldSvg from "../../images/templates/pre-sales/world.svg";
import AppReimbursement from "../../organisms/app-reimbursement/app_reimbursement";
import PreSalesFaqs from "../../organisms/faqs/PreSalesFaqs";
import PreSalesFooter from "../../organisms/footer/PreSalesFooter";
import Hero from "../../organisms/hero/Hero";
import KspList from "../../organisms/ksp-list/KspList";
import LandingBottomCta from "../../organisms/landing-bottom-cta/LandingBottomCta";
import Meta from "../../organisms/meta/meta";
import Navigation from "../../organisms/navigation/Navigation";
import PageWrapper from "../../organisms/page-wrapper/PageWrapper";
import Reimbursement from "../../organisms/reimbursement/reimbursement";
import { CountryCode, Locale } from "../../settings/countries";
import useAxeptioScriptLoader from "../../utils/hooks/useAxeptioScriptLoader";
import * as styles from "./PreSalesLandingPage.module.scss";

interface PreSalesLandingPageProps {
  pageContext: {
    locale: string;
    language: string;
    defaultLanguage: string;
  };
  location: Location | undefined;
}

const PreSalesLandingPage = ({ pageContext, location }: PreSalesLandingPageProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const { country } = useCountry();

  useAxeptioScriptLoader(country.language.code.split("-")[0].toLowerCase());

  const normalizedCountryCode = country?.language.code.toLocaleLowerCase() as CountryCode;
  const faqTranslations = i18n.getResourceBundle(country?.language.code, "translation") as Record<
    string,
    any
  >;
  if (!faqTranslations) return <></>;
  const faqItems = Object.entries(faqTranslations)
    .filter(([key]) => key.startsWith("landing.faqs.item_"))
    .reduce((acc, [key, value]) => {
      const itemNumber = key.match(/item_(\d+)/)?.[1];
      const type = key.endsWith(".title") ? "title" : "text";

      if (itemNumber) {
        const index = parseInt(itemNumber, 10) - 1;
        acc[index] = acc[index] || {};
        acc[index][type] = value;
      }

      return acc;
    }, [] as Array<{ title?: string; text?: string }>)
    .filter((item) => item && item.title && item.text);

  const kspList = [
    {
      description: t("landing.ksp_list.item_1.description"),
      title: t("landing.ksp_list.item_1.title"),
      image: { file: { url: WorldSvg } },
    },
    {
      description: t("landing.ksp_list.item_2.description"),
      title: t("landing.ksp_list.item_2.title"),
      image: { file: { url: DogAndCatSvg } },
    },
    {
      description: t("landing.ksp_list.item_3.description"),
      title: t("landing.ksp_list.item_3.title"),
      image: { file: { url: SyringeSvg } },
    },
    {
      description: t("landing.ksp_list.item_4.description"),
      title: t("landing.ksp_list.item_4.title"),
      image: { file: { url: PhoneCallSvg } },
    },
  ];

  return (
    <>
      <Meta
        title={t("landing.meta.title")}
        description={"landing.meta.description"}
        slug={"/"}
        alternateVersions={""}
        lang={pageContext.language as Locale}
        bodyClass={styles.landing}
        disableTranslations={false}
      />
      <PageWrapper>
        <Navigation
          location={location}
          countryCodeIso={normalizedCountryCode}
          adoptionClassName={styles.navigation}
        />
        <main className={cn(styles.main, "or-main")}>
          <Hero
            data={{
              title: t("landing.hero.title"),
              list: [
                t("landing.hero.list.item_1"),
                t("landing.hero.list.item_2"),
                t("landing.hero.list.item_3", { country: country.name }),
              ],
              shouldShowPetSpeciesSelector: true,
              reviewsLink: `https://${country.iso.toLocaleLowerCase()}.trustpilot.com/review/barkibu.com`,
            }}
            countryCodeIso={normalizedCountryCode}
            adoptionClassName={styles.hero}
          />
          <KspList
            data={{ title: t("landing.ksp_list.title"), ksps: kspList }}
            variantName={"benefits"}
            adoptionClassName={styles.kspList}
          />
          <Reimbursement
            data={{
              title: t("landing.reimbursement.title"),
              subtitle: t("landing.reimbursement.subtitle"),
            }}
            adoptionClassName={styles.reimbursement}
          />
          <AppReimbursement
            data={{
              title: t("landing.app_reimbursement.title"),
              subtitle: t("landing.app_reimbursement.subtitle"),
            }}
            adoptionClassName={styles.appReimbursement}
          />
          <PreSalesFaqs title={t("landing.faqs.title")} faqItems={faqItems} />
          <LandingBottomCta
            data={{
              cta: t("landing.bottom_cta.title"),
              subtitle: t("landing.bottom_cta.subtitle"),
            }}
            countryCodeIso={normalizedCountryCode}
            adoptionClassName={styles.homeBottomCta}
          />
        </main>
        <PreSalesFooter adoptionClassName={styles.footer} />
        <BackgroundShape adoptionClassName={styles.backgroundShape} />
        <Icons />
      </PageWrapper>
    </>
  );
};

export default PreSalesLandingPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
